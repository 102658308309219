import React from 'react';
import 'url-search-params-polyfill';
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/browser';
import {Amplify} from 'aws-amplify';
import {render} from 'react-dom';

import {initSegment} from '@fo/shared-analytics/tracking/segment';
import {DEV, STAGING} from '@fo/shared-types/environments';

import WrapPage from './src/WrapPageElement';
import WrapRoot from './src/WrapRootElement';

let isSentryInitialised = false;
let isSegmentInitialised = false;

const initSentry = (e) => {
  if (process.env.NODE_ENV !== DEV) {
    const consent = e?.detail || {};
    if (consent.sentry || consent.performance) {
      Sentry.init({
        dsn: 'https://3055b70e38c741e9ba585e0d9fa30fcb@o360347.ingest.sentry.io/5608696',
        environment: process.env.STAGE,
        release: process.env.RELEASE,
      });
      window.Sentry = Sentry;
    }
  }
};

export const onClientEntry = () => {
  if (!window.fundingOptions) {
    window.fundingOptions = {};
  }

  // Initialises Sentry
  if (!isSentryInitialised) {
    initSentry();
    isSentryInitialised = true;
  }

  // Initialises Segment
  if (!isSegmentInitialised) {
    initSegment();
    isSegmentInitialised = true;
  }

  // Register AWS Amplify/Cognito config (at the moment, not for prod)
  Amplify.configure({
    Auth: {
      oauth: {},
      region: process.env.AWS_REGION,
      userPoolId: process.env.AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.AWS_USER_POOL_WEB_CLIENT_ID,
    },
  });
};

function getLaunchDarklyEnvClientId() {
  if (process.env.STAGE === STAGING) {
    return process.env.LAUNCHDARKLY_STAGING_CLIENT_ID;
  }
  if (process.env.STAGE === DEV) {
    return process.env.LAUNCHDARKLY_DEVELOPMENT_CLIENT_ID;
  }
  return process.env.LAUNCHDARKLY_PRODUCTION_CLIENT_ID;
}

export const replaceHydrateFunction = () => async (element, container, callback) => {
  'use client';

  const LDProvider = await asyncWithLDProvider({
    clientSideID: getLaunchDarklyEnvClientId(),
    options: {
      allAttributesPrivate: true,
    },
  });

  render(<LDProvider>{element}</LDProvider>, container, callback);
};

export const wrapPageElement = WrapPage;
export const wrapRootElement = WrapRoot;
