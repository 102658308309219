import {injectGlobal} from '@emotion/css';

import {colours} from './variables/colours';
import {fonts} from './variables/fonts';
import {spacing} from './variables/spacing';

export const baseStyles = () => injectGlobal`
  html {
    box-sizing: border-box;
    /* stylelint-disable-next-line unit-allowed-list */
      font-size: 10px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: ${fonts.size.L};
    font-family: ${fonts.family.PRIMARY};
    line-height: ${fonts.lineHeight.BASE};
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style: none;
  }
   /* HTML Elements */
  input,
  select,
  option,
  textarea {
    width: 100%;
    height: 4rem;
    margin: ${spacing.XXXS} 0 0;
    padding-left: ${spacing.XXS};
    color: ${colours.font.BLACK};
    font-size: ${fonts.size.L};
    border: 1px solid ${colours.border.LIGHT_GRAY};
    outline: none;
    &:focus {
      border: 2px solid ${colours.semantic.WARNING};
    }
    &::placeholder {
      color: ${colours.font.PLACEHOLDER_GRAY};
    }
  }
  .page--loading {
    margin: ${spacing.M} auto;
  }
  

/* Screen reader only class */
/* stylelint-disable unit-allowed-list */
.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important; // stylelint-disable-line sh-waqar/declaration-use-variable
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
}
/* stylelint-enable unit-allowed-list */

/* OneTrust Overrides */
#onetrust-pc-sdk {
  && {
    font-size: 1.6rem;
  }
}
`;
