import React, {ReactNode, ReactElement, createContext, useState, useEffect, useMemo, useContext} from 'react';
import {AxiosResponse} from 'axios';

import {Account, Scope, User, PartnerType} from '@fo/shared-types';
import {UPDATE_USER_EVENT} from '@fo/shared-types/constants';

export interface IdentityContextValue {
  isLoading: boolean;
  isLoggedIn: boolean;
  isUserAdvisor: boolean;
  isUserBRSAdvisor: boolean;
  logout: (isAuthenticatedPage?: boolean) => Promise<void>;
  updateProfile: (profile: Partial<User>, brsApplicationReference?: string) => Promise<void>;
  updateUser: () => Promise<void>;
  user?: User;
}

export interface IdentityObject {
  getAccount: () => Promise<Account | undefined>;
  getProfile: () => Promise<AxiosResponse<SystemOfRecord.AccountHolderDetails>>;
  getPartnerProfile: () => Promise<AxiosResponse<SystemOfRecord.WritePartnerModelAccount>>;
  updateProfile: (
    accountData: Partial<SystemOfRecord.AccountHolderDetails>,
    brsApplicationReference?: string
  ) => Promise<AxiosResponse<SystemOfRecord.AccountHolderDetails>>;
  logout: () => Promise<void>;
}

export const defaultIdentity: IdentityContextValue = {
  isLoading: false,
  isLoggedIn: false,
  isUserAdvisor: false,
  isUserBRSAdvisor: false,
  logout: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  user: undefined,
};

// we can't use `useIdentity()` here as it uses "useState", and at this point in the code
// that is an invalid hook call (it's not a functional component)
const IdentityContext = createContext<IdentityContextValue>(defaultIdentity);

interface Props {
  children: ReactNode;
  dispatchTrackingSuperEvent: (user: Partial<User>) => void;
  identity: IdentityObject;
}

export const isUserBRSAdvisor = (user?: User): boolean => {
  if (!user) {
    return false;
  }

  if (!user.scopes?.includes(Scope.ADVISOR)) {
    return false;
  }

  if (user.partner?.partner_type === PartnerType.BANK_REFERRAL_SCHEME) {
    return true;
  }

  return false;
};

function IdentityContextProvider({children, dispatchTrackingSuperEvent, identity}: Props): ReactElement {
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [profile, setProfile] = useState<Partial<User> | undefined>(undefined);
  const [identityLoading, setIdentityLoading] = useState<boolean>(true);
  const user = useMemo<User | undefined>(() => {
    if (!account || !profile) {
      return undefined;
    }
    return {...account, ...profile};
  }, [account, profile]);

  dispatchTrackingSuperEvent({});

  // @todo review so that we remove setUser and we setProfile and setAccount separately
  const setUser = (currentUser: User | undefined) => {
    // if setting user to undefined, then user is logging out
    if (!currentUser) {
      setAccount(undefined);
      setProfile(undefined);
      return;
    }

    const {first_name, last_name, mobile_number, landline_number, date_of_birth, marketing_opt_in, ...accountResponse} =
      currentUser;
    setAccount(accountResponse);
    setProfile({
      first_name,
      last_name,
      email: accountResponse.email,
      mobile_number,
      landline_number,
      date_of_birth,
      marketing_opt_in,
    });
    dispatchTrackingSuperEvent({
      first_name,
      last_name,
      email: accountResponse.email,
      jwt: accountResponse.jwt,
      cognitoId: accountResponse.cognitoId,
    });
  };

  // checks AWS Auth internal state to see details of logged-in user
  const updateUser = async () => {
    setIdentityLoading(true);
    const currentAccount = await identity.getAccount();
    if (!currentAccount) {
      // user is not logged in
      setIdentityLoading(false);
      return;
    }

    setAccount(currentAccount);

    const {data: currentProfile} = await identity.getProfile();
    setProfile(currentProfile);
    dispatchTrackingSuperEvent({
      email: currentProfile.email,
      first_name: currentProfile.first_name,
      last_name: currentProfile.last_name,
      jwt: currentAccount.jwt,
      cognitoId: currentAccount.cognitoId,
    });
    // if user has correct scope, update the profile with the partner info
    if (currentAccount.scopes?.includes(Scope.ADVISOR)) {
      const {data: currentPartnerProfile} = await identity.getPartnerProfile();
      setProfile({
        ...currentProfile,
        partner: {
          ...currentPartnerProfile,
        },
      });
    }

    setIdentityLoading(false);
  };

  // updates according to System of Record
  const updateProfile = async (
    newProfileDetails: Partial<SystemOfRecord.AccountHolderDetails>,
    brsApplicationReference?: string
  ) => {
    const {data: updatedProfile} = await identity.updateProfile(newProfileDetails, brsApplicationReference);
    setProfile(updatedProfile);
  };

  // event handler to set a new user
  const updateHandler: EventListener = ({detail: currentUser}: CustomEventInit<User>) => {
    // @todo update function so profile and account are set separately. Deprecate setUser function
    setUser(currentUser);
  };

  const logout = async () => {
    await identity.logout();
  };

  useEffect(() => {
    updateUser();

    // subscribe to update user via event
    window.addEventListener(UPDATE_USER_EVENT, updateHandler);

    return () => {
      window.removeEventListener(UPDATE_USER_EVENT, updateHandler);
    };
  }, []);

  return (
    <IdentityContext.Provider
      value={{
        isLoading: identityLoading,
        isLoggedIn: !!user,
        isUserAdvisor: !!user?.scopes?.includes(Scope.ADVISOR),
        isUserBRSAdvisor: isUserBRSAdvisor(user),
        logout,
        updateUser,
        updateProfile,
        user,
      }}
    >
      {children}
    </IdentityContext.Provider>
  );
}

export const useIdentityContext = (): IdentityContextValue => useContext(IdentityContext);

export {IdentityContextProvider};

export default IdentityContext;
