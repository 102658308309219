import {injectGlobal} from '@emotion/css';

export const cssVarsElementSizing = (): void => injectGlobal`
  :root {  
    --sidebar-width: 19rem;
    --sidebar-min-height: 19rem;
  }
`;

export const sidebarWidth = 'var(--sidebar-width)';
export const sidebarMinHeight = 'var(--sidebar-min-height)';

export enum ContainerSizes {
  XXXL = '144rem',
  XXL = '128rem',
  XL = '111.2rem',
  LG = '102.4rem',
  MD = '80rem',
  SM = '76rem',
  XS = '66rem',
  MIN = '28rem',
}

export const elementSize = {
  sidebarWidth,
  sidebarMinHeight,
  container: ContainerSizes,
};
