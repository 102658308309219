import {AxiosResponse} from 'axios';
import SystemOfRecordService, {SOREndpoints} from './SystemOfRecordService';

export interface IClientMetadata {
  language: string;
  is_advisor: boolean;
}

export interface ISignUpPayload {
  email: string;
  password: string;
  client_metadata: IClientMetadata;
}

const signUp = async (body: ISignUpPayload): Promise<AxiosResponse> =>
  SystemOfRecordService.post(SOREndpoints.SIGN_UP, body);

const FoIdentity = {
  signUp,
};

export default FoIdentity;
