export const isBrowser: boolean = typeof window !== 'undefined';
export const getIsIntersectionObserverSupported = (): boolean => isBrowser && window.IntersectionObserver !== undefined;

export const isWebpSupported = (): boolean => {
  if (!isBrowser) {
    return false;
  }

  const canvas = document.createElement('canvas');

  if (canvas.getContext && canvas.getContext('2d')) {
    return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  return false;
};
