import {Auth} from 'aws-amplify';
import {AxiosResponse} from 'axios';

import {User, Account, PartnerType, ObjectOfAny, AdvisorType, PartnerLegalStatus} from '@fo/shared-types';
import {EMAIL_LOCALE, UPDATE_USER_EVENT} from '@fo/shared-types/constants';
import getAccountFromCognitoUserResponse from '@fo/shared-getters/getAccountFromCognitoUserResponse';
import SystemOfRecordService, {SOREndpoints} from './SystemOfRecordService';
import setUserAuthToken from './setUserAuthToken';
import FoIdentity from './FoIdentity';

export interface ChangePassword {
  newPassword: string;
  oldPassword: string;
}

const changePassword = ({newPassword, oldPassword}: ChangePassword): Promise<'SUCCESS'> => {
  const clientMetadata = {
    language: EMAIL_LOCALE,
  };

  return Auth.currentAuthenticatedUser().then((user) =>
    Auth.changePassword(user, oldPassword, newPassword, clientMetadata)
  );
};

export interface ConfirmEmailAddress {
  confirmationCode: string;
}

const confirmEmailAddress = ({confirmationCode}: ConfirmEmailAddress): Promise<User> =>
  Auth.verifyCurrentUserAttributeSubmit('email', confirmationCode).then(async () => {
    const updatedUser: User = await Auth.currentAuthenticatedUser({bypassCache: true});
    return updatedUser;
  });

export interface GetUserByEmail {
  confirmationCode: string;
  emailAddress: string;
  params?: {
    forceAliasCreation?: boolean;
  };
}

const getUserByEmail = ({emailAddress, confirmationCode, params}: GetUserByEmail): Promise<ObjectOfAny> =>
  Auth.confirmSignUp(emailAddress, confirmationCode, params);

export interface CompleteNewPassword {
  password: string;
}

const completeNewPassword = ({password}: CompleteNewPassword): Promise<ObjectOfAny> => {
  const requiredAttributes = {};
  const clientMetadata = {
    language: EMAIL_LOCALE,
  };

  return Auth.currentAuthenticatedUser({bypassCache: true}).then((user) =>
    Auth.completeNewPassword(user, password, requiredAttributes, clientMetadata)
  );
};

const getProfile = (): Promise<AxiosResponse<SystemOfRecord.AccountHolderDetails>> =>
  SystemOfRecordService.get<SystemOfRecord.AccountHolderDetails>(SOREndpoints.CUSTOMER_PROFILE);

const getPartnerProfile = (): Promise<AxiosResponse<SystemOfRecord.WritePartnerModelAccount>> =>
  SystemOfRecordService.get<SystemOfRecord.WritePartnerModelAccount>(SOREndpoints.ADVISOR_PROFILE);

export const emitUpdateUserEvent = (userData: User | undefined): void => {
  // trigger update in IdentityContext
  window.dispatchEvent(new CustomEvent(UPDATE_USER_EVENT, {detail: userData}));
};

const updateProfile = async (
  accountData: Partial<SystemOfRecord.AccountHolderDetails>,
  brsApplicationReference?: string
): Promise<AxiosResponse<SystemOfRecord.AccountHolderDetails>> => {
  const response = await SystemOfRecordService.post<SystemOfRecord.AccountHolderDetails>(
    brsApplicationReference
      ? `${SOREndpoints.CUSTOMER_PROFILE}?application_reference=${brsApplicationReference}`
      : SOREndpoints.CUSTOMER_PROFILE,
    {
      ...accountData,
      ...(brsApplicationReference ? {brs_consent_opt_in: true} : {}),
    }
  );

  return response;
};

const updatePartnerProfile = async (
  partnerData: Partial<SystemOfRecord.WritePartnerModelAccount>
): Promise<AxiosResponse<SystemOfRecord.WritePartnerModelAccount>> => {
  const response = await SystemOfRecordService.post<SystemOfRecord.WritePartnerModelAccount>(
    SOREndpoints.ADVISOR_PROFILE,
    partnerData
  );

  return response;
};

export interface Login {
  emailAddress: string;
  password: string;
}

const login = async ({emailAddress, password}: Login, ignoreUserTypeCheck = false): Promise<Account> => {
  const clientMetadata = {
    language: EMAIL_LOCALE,
  };
  // This is ignored used for the TSB partner page form
  // when user is creating an account
  if (!ignoreUserTypeCheck) {
    // checks type of user to decide what flow to follow
    const userTypeResponse = await SystemOfRecordService.post(SOREndpoints.LOGIN, {email: emailAddress});
    if (userTypeResponse.status !== 200) {
      throw new Error(userTypeResponse.data.message);
    }
  }
  const response = await Auth.signIn(emailAddress, password, clientMetadata);
  const userAccount = getAccountFromCognitoUserResponse(response);
  setUserAuthToken(userAccount.jwt);
  return userAccount;
};

const logout = async (): Promise<void> => {
  await Auth.signOut({global: true});
  emitUpdateUserEvent(undefined);
  Promise.resolve();
};

const resendConfirmEmail = (): Promise<void> => Auth.verifyCurrentUserAttribute('email');

export interface CreateAccount {
  emailAddress: string;
  firstName?: string;
  marketingOptIn: boolean;
  password: string;
  surname?: string;
  telephone?: string;
  isAdvisor?: boolean;
}

export type CreateAdvisorAccount = CreateAccount & {
  companyNumber?: string;
  companyName?: string;
  partnerType?: PartnerType | '';
  advisorType?: AdvisorType | '';
  legalStatus?: PartnerLegalStatus | '';
  introducer_affiliate_id?: string;
};

const createAccount = async (
  {
    emailAddress,
    firstName,
    surname,
    marketingOptIn,
    password,
    telephone,
    isAdvisor,
    companyNumber,
    companyName,
    partnerType,
    legalStatus,
    introducer_affiliate_id,
  }: CreateAdvisorAccount,
  isUserTypeCheckDisabled = false
): Promise<User> => {
  // @todo this typing could be better

  // create cognito account
  await FoIdentity.signUp({
    email: emailAddress,
    password,
    client_metadata: {
      is_advisor: Boolean(isAdvisor), // only accepts a string
      language: EMAIL_LOCALE,
    },
  });

  const userAccount = await login({emailAddress, password}, isUserTypeCheckDisabled);
  // we need to manually trigger the verification email since auto-verification is turned on in Cognito
  // this will only work _after_ the login has happened

  // set the customer information before we set the partner information
  const userProfileData: SystemOfRecord.AccountHolderDetails = {
    first_name: firstName,
    last_name: surname,
    email: emailAddress,
    mobile_number: telephone,
    marketing_opt_in: marketingOptIn,
  };

  // create new user profile in System of Record
  const userProfile = await updateProfile(userProfileData);

  // if signing up as an advisor, also create new partner profile in System of
  let partnerProfile;

  if (isAdvisor) {
    const partnerProfileData: SystemOfRecord.WritePartnerModelAccount = {
      company_name: companyName,
      company_number: companyNumber,
      partner_type: partnerType || PartnerType.ADVISORY_ACCOUNT,
      legal_status: legalStatus || PartnerLegalStatus.LIMITED_COMPANY,
      introducer_affiliate_id: introducer_affiliate_id || '',
    };
    partnerProfile = await updatePartnerProfile(partnerProfileData);
  }

  const userData: User = {
    ...userAccount,
    ...userProfile.data,
    partner: {
      ...partnerProfile?.data,
    },
  };

  // trigger update in IdentityContext
  emitUpdateUserEvent(userData);
  return userData;
};

export interface ForgotPassword {
  emailAddress: string;
}

const forgotPassword = ({emailAddress}: ForgotPassword): Promise<void> => {
  const clientMetadata = {
    language: EMAIL_LOCALE,
  };
  return Auth.forgotPassword(emailAddress, clientMetadata);
};

const getAccount = async (): Promise<Account | undefined> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const account = getAccountFromCognitoUserResponse(user);

    setUserAuthToken(account.jwt);

    return account;
  } catch (e) {
    // falls into here when no user is logged in
    return undefined;
  }
};

export interface ResetPassword {
  confirmationCode: string;
  emailAddress: string;
  password: string;
}

const resetPassword = ({confirmationCode, emailAddress, password}: ResetPassword): Promise<string> => {
  const clientMetadata = {
    language: EMAIL_LOCALE,
  };
  return Auth.forgotPasswordSubmit(emailAddress, confirmationCode, password, clientMetadata);
};

const Identity = {
  changePassword,
  completeNewPassword,
  confirmEmailAddress,
  createAccount,
  forgotPassword,
  getAccount,
  getPartnerProfile,
  getProfile,
  getUserByEmail,
  login,
  logout,
  resendConfirmEmail,
  resetPassword,
  updatePartnerProfile,
  updateProfile,
};

export default Identity;
