import {useEffect} from 'react';

import {isBrowser} from '@fo/shared/utils/browserSupport';
import {PRODUCTION} from '@fo/shared-types/environments';

function CookieLoader(): null {
  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    const onPageLoad = () => {
      window.removeEventListener('load', onPageLoad);
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.src =
        process.env.STAGE === PRODUCTION
          ? `https://cookie-cdn.cookiepro.com/consent/${process.env.COOKIE_PRO_DOMAIN_SCRIPT}/otSDKStub.js`
          : 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js';
      script.dataset.domainScript =
        process.env.STAGE === PRODUCTION
          ? process.env.COOKIE_PRO_DOMAIN_SCRIPT ?? ''
          : `${process.env.COOKIE_PRO_DOMAIN_SCRIPT ?? ''}-test`;
      head?.appendChild(script);
    };
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
    }
  }, []);

  return null;
}

export default CookieLoader;
