import React, {useState, createContext, ReactChild, useContext, ReactElement} from 'react';
import {createPortal} from 'react-dom';

type ModalContextValue = (modalContent: ReactChild | undefined, force?: boolean) => boolean;

export const ModalContext = createContext<ModalContextValue>(() => true);

export const useSetModalContent = (): ModalContextValue => useContext(ModalContext);

interface Props {
  children: ReactChild | ReactChild[];
}

function ModalContextProvider({children}: Props): ReactElement {
  const [modal, setModal] = useState<ReactChild | undefined>(undefined);

  // returns false if trying to set a modal failed
  // because another one is currently being rendered
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const getSetModal = (modalContent: ReactChild | undefined, force = false): boolean => {
    // Foces override of current modal
    if (force) {
      setModal(modalContent);
      return true;
    }
    // trying to set modal content while there is already a modal being shown
    if (modalContent && modal) {
      return false;
    }
    // setting a modal since no modal is being shown yet
    if (!!modalContent && !modal) {
      setModal(modalContent);
    }
    // no content (undefined) was passed, so clear the modal
    if (!modalContent) {
      setModal(undefined);
    }
    return true;
  };

  const portalElement = typeof document !== 'undefined' ? document.getElementById('modalPortal') : undefined;

  return (
    <ModalContext.Provider value={getSetModal}>
      {children}
      {portalElement ? createPortal(modal, portalElement) : null}
    </ModalContext.Provider>
  );
}

export default ModalContextProvider;
