/// /////////////////////////
/// USER & ACCOUNT TYPES ///
/// /////////////////////////

export enum Scope {
  ADVISOR = 'advisor',
  CUSTOMER = 'customer',
}

export enum IdentityChallenge {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

export type Account = {
  challengeName?: IdentityChallenge;
  cognitoId?: string;
  email: string;
  emailVerified: boolean;
  jwt?: string;
  scopes?: Scope[];
};

export type User = Account &
  SystemOfRecord.AccountHolderDetails & {
    partner?: SystemOfRecord.ReadPartnerModelAccount;
  };

export enum AdvisorType {}

export enum PartnerType {
  BANK_REFERRAL_SCHEME = 'bank_brs',
  NOT_BANK_REFERRAL_SCHEME = 'bank_non_brs',
  PARTNER_PAY_PER_LEAD = 'partner_ppl',
  PARTNER_REVENUE_SHARE = 'partner_rev_share',
  PARTNER_HYBRID = 'partner_hybrid',
  ADVISORY_ACCOUNT = 'advisory_accountant',
  ADVISORY_BROKER = 'advisory_broker',
  ADVISORY_OTHER = 'advisory_other',
}

export enum PartnerLegalStatus {
  LIMITED_COMPANY = 'limited_company',
  SOLE_TRADER = 'sole_trader',
  LIMITED_PARTNERSHIP = 'limited_partnership',
  PARTNERSHIP_THREE_OR_LESS = 'partnership_with_three_or_fewer',
  PARTNERSHIP_FOUR_OR_MORE = 'partnership_with_four_or_more_partners',
  OTHER = 'other',
}
