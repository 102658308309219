import React, {ReactNode} from 'react';

export const b = (chunks: ReactNode[]): JSX.Element => <strong>{chunks}</strong>;
export const br = (): JSX.Element => <br />;
export const lightItalic = (chunks: ReactNode[]): JSX.Element => <span className="lightItalic">{chunks}</span>;

export const noBr = (chunks: ReactNode[]): JSX.Element => <span className="noBreakText">{chunks}</span>;
export const p = (chunks: ReactNode[]): JSX.Element => <p>{chunks}</p>;
export const span = (chunks: ReactNode[]): JSX.Element => <span>{chunks}</span>;
export const strong = (chunks: ReactNode[]): JSX.Element => <strong>{chunks}</strong>;

const defaultRichTextElements = {
  b,
  br,
  lightItalic,
  noBr,
  p,
  span,
  strong,
};

export default defaultRichTextElements;
