import {useState, useEffect, createContext} from 'react';

import {isBrowser} from '@fo/shared/utils/browserSupport';

export enum Device {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export const useDevice = (): {device: Device} => {
  const deviceMinWidthMapping = {
    tablet: 760,
    desktop: 1024,
  };
  const getDevice = () => {
    if (isBrowser && window.innerWidth < deviceMinWidthMapping.tablet) {
      return Device.MOBILE;
    }
    if (isBrowser && window.innerWidth < deviceMinWidthMapping.desktop) {
      return Device.TABLET;
    }
    return Device.DESKTOP;
  };

  const [device, setDevice] = useState(getDevice());

  useEffect(() => {
    const handleResize = () => {
      const currentDevice = getDevice();
      setDevice(currentDevice);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {device};
};

const DeviceContext = createContext({device: Device.MOBILE});
export default DeviceContext;
