import {Component, ErrorInfo, ReactNode} from 'react';
import {Scope} from '@sentry/types';
import {navigate} from 'gatsby';

interface Errors extends ErrorInfo {
  [key: string]: string;
}

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
}

class SentryErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {error: null};
  }

  componentDidCatch(error: Error, errorInfo: Errors): void {
    this.setState({error});
    if (window.Sentry) {
      window.Sentry.configureScope((scope: Scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
      window.Sentry.captureException(error);
    }
  }

  render(): ReactNode {
    const {error} = this.state;
    const {children} = this.props;
    if (error) {
      // intl context is not available at this level so this must be hard coded
      navigate(`/500/?r=${window.location.href}&e=${error.message}`);
    }
    // when there's not an error, render children untouched
    return children;
  }
}

export default SentryErrorBoundary;
