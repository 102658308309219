import {cssVarsColours} from './variables/colours';
import {cssVarsElementSizing} from './variables/elementSizing';
import {cssVarsFonts} from './variables/fonts';
import {cssVarsSpacing} from './variables/spacing';
import {baseStyles} from './baseStyles';
import {typography, addFonts} from './typography';
import {flexboxgrid} from './flexboxgrid';

/**
 * Uncomment the import and where called to CSS debug
 */
// import {styleDebug} from './debug';

export const injectGlobalStyles = (): void => {
  // Vars
  cssVarsFonts();
  cssVarsColours();
  cssVarsSpacing();
  cssVarsElementSizing();

  // Base
  addFonts();
  baseStyles();
  typography();
  flexboxgrid();
  // styleDebug();
};
