import React, {ReactChild, ReactElement} from 'react';
import {IntlProvider} from 'react-intl';
import {CookiesProvider} from 'react-cookie';

import {DEFAULT_LOCALE, LANGUAGE_LOCALE} from '@fo/shared-types/constants';
import useHandleFoid from '@fo/shared-hooks/useHandleFoid';
import useHandleLandingUrl from '@fo/shared-hooks/useHandleLandingUrl';
import {getMessages} from '@fo/shared-i18n';
import defaultRichTextElements from '@fo/shared-helpers/defaultRichTextElements';
import {User} from '@fo/shared-types';

import ModalContextProvider from './ModalContextProvider';
import {IdentityContextProvider, IdentityObject} from './IdentityContext';
import DeviceContext, {useDevice} from './DeviceContext';

export interface SiteContextProviderProps {
  children: ReactChild | ReactChild[];
  dispatchTrackingSuperEvent: (user: Partial<User>) => void;
  identity: IdentityObject;
}

const SiteContextProvider = ({
  children,
  dispatchTrackingSuperEvent,
  identity,
}: SiteContextProviderProps): ReactElement => {
  const {device} = useDevice();
  useHandleFoid();
  useHandleLandingUrl();

  // set Identity first, so we can pull locale/etc out of user object later
  return (
    <CookiesProvider>
      <IntlProvider
        key={LANGUAGE_LOCALE}
        locale={DEFAULT_LOCALE}
        messages={getMessages()}
        defaultRichTextElements={defaultRichTextElements}
      >
        <IdentityContextProvider dispatchTrackingSuperEvent={dispatchTrackingSuperEvent} identity={identity}>
          <DeviceContext.Provider value={{device}}>
            <ModalContextProvider>{children}</ModalContextProvider>
          </DeviceContext.Provider>
        </IdentityContextProvider>
      </IntlProvider>
    </CookiesProvider>
  );
};

export default SiteContextProvider;
