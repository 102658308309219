import {css} from '@emotion/css';

import {colours} from '../variables';

export const yellowSeparator = css`
  width: 8rem;
  height: 0.3rem;
  margin: 0 auto;
  background-color: ${colours.brand.PRIMARY};
  border: none;
`;

export const lineClamp = (numLines: number): string => css`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${numLines};
  -webkit-box-orient: vertical;
`;
