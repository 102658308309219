import {css, injectGlobal} from '@emotion/css';

import {Breakpoints, mq} from './mixins';

// Main Variables
/* stylelint-disable-next-line unit-allowed-list */
const outerMargin = 20;
const containerTablet = Breakpoints.TABLET - outerMargin * 2;
const containerDesktop = Breakpoints.DESKTOP_LG - outerMargin * 2;

const getColumnsBaseSetup = (sizeName) => css`
  .col-${sizeName},
    .col-${sizeName}-1,
    .col-${sizeName}-2,
    .col-${sizeName}-3,
    .col-${sizeName}-4,
    .col-${sizeName}-5,
    .col-${sizeName}-6,
    .col-${sizeName}-7,
    .col-${sizeName}-8,
    .col-${sizeName}-9,
    .col-${sizeName}-10,
    .col-${sizeName}-11,
    .col-${sizeName}-12,
    .col-${sizeName}-offset-0,
    .col-${sizeName}-offset-1,
    .col-${sizeName}-offset-2,
    .col-${sizeName}-offset-3,
    .col-${sizeName}-offset-4,
    .col-${sizeName}-offset-5,
    .col-${sizeName}-offset-6,
    .col-${sizeName}-offset-7,
    .col-${sizeName}-offset-8,
    .col-${sizeName}-offset-9,
    .col-${sizeName}-offset-10,
    .col-${sizeName}-offset-11,
    .col-${sizeName}-offset-12 {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
  .col-${sizeName}-narrow {
    flex: none;
  }
`;

const getColumnsWidth = (sizeName) => {
  const styles = {};

  Array.from({length: 13}).forEach((_el, index) => {
    if (index === 0) {
      styles[`.col-${sizeName}`] = {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: '100%',
      };
    } else {
      const value = `calc(100% * ${index / 12})`;
      styles[`.col-${sizeName}-${index}`] = {
        flexBasis: value,
        maxWidth: value,
      };
    }
  });

  return css(styles);
};

const getColumnsOffset = (sizeName) => {
  const styles = {};

  Array.from({length: 13}).forEach((_el, index) => {
    const value = `calc(100% * ${index / 12})`;

    styles[`.col-${sizeName}-offset-${index}`] = {
      marginLeft: value,
    };
  });

  return css(styles);
};

const getContentPositions = (sizeName) => css`
  .start-${sizeName} {
    justify-content: flex-start;
    text-align: start;
  }
  .center-${sizeName} {
    justify-content: center;
    text-align: center;
  }
  .end-${sizeName} {
    justify-content: flex-end;
    text-align: end;
  }
  .top-${sizeName} {
    align-items: flex-start;
  }
  .middle-${sizeName} {
    align-items: center;
  }
  .bottom-${sizeName} {
    align-items: flex-end;
  }
  .around-${sizeName} {
    justify-content: space-around;
  }
  .between-${sizeName} {
    justify-content: space-between;
  }
  .first-${sizeName} {
    order: -1;
  }
  .last-${sizeName} {
    order: 1;
  }
`;

const getPaddingless = (sizeName) => css`
  .paddingless-${sizeName} {
    padding: 0;
  }
  .paddingless-${sizeName}-only {
    ${sizeName === 'xs' &&
    css`
      ${mq(Breakpoints.TABLET)} {
        padding: 0;
      }
    `}
    ${sizeName === 'sm' &&
    css`
      ${mq(Breakpoints.DESKTOP)} {
        padding: 0;
      }
    `}

    ${sizeName === 'md' &&
    css`
      padding: 0;
    `}
  }
`;

const getFullColRules = (sizeName) => css`
  ${getColumnsBaseSetup(sizeName)};
  ${getColumnsWidth(sizeName)}
  ${getColumnsOffset(sizeName)}
  ${getContentPositions(sizeName)}
  ${getPaddingless(sizeName)}
`;

export const flexboxgrid = () => injectGlobal`
// Global classes

.container {
  box-sizing: border-box;
  width: 100%;
  min-width: #{${Breakpoints.TABLET} - ${outerMargin} * 2}px;
  margin-right: auto;
  margin-left: auto;
  padding: {
    right: ${outerMargin}px;
    left: ${outerMargin}px;
  }
}

.reverse {
  flex-direction: column-reverse;
}

.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  .reverse {
    flex-direction: row-reverse;
  }
}

// **********************************
// Beginning of screen size specific classes
// **********************************
${getFullColRules('xs')}

${mq(Breakpoints.TABLET)} {
  .container {
    width: ${containerTablet}px;
  }
  ${getFullColRules('sm')}
}

${mq(Breakpoints.DESKTOP)} {
  .container {
    width: 100%;
    max-width: ${containerDesktop}px;
  }
  ${getFullColRules('md')}
}

`;
