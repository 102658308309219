import React, {ReactElement} from 'react';
import SentryErrorBoundary from './components/SentryErrorBoundary';

export interface WrapRootElementProps {
  element: Element;
}

const wrapRootElement = ({element}: WrapRootElementProps): ReactElement => (
  <SentryErrorBoundary>{element}</SentryErrorBoundary>
);

export default wrapRootElement;
