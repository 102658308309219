// DOCS: https://system-of-record.dev.service.fundingoptions.com/customer/
import axios from 'axios';
import {User} from '@fo/shared-types';
import getBearerToken from '@fo/shared-getters/getBearerToken';
import getServiceUrl, {Service} from '../getServiceUrl';

const serviceHost = getServiceUrl(Service.SYSTEM_OF_RECORD_SERVICE);
const SystemOfRecordService = axios.create({baseURL: serviceHost});

// Authorization header control
export interface SetAuthTokenOpts {
  token?: string;
  user?: User;
}
export const setAuthToken = ({token, user}: SetAuthTokenOpts): void => {
  const authToken = token ? `Bearer ${token}` : getBearerToken(user);
  if (authToken) {
    SystemOfRecordService.defaults.headers.common.Authorization = authToken;
  } else {
    delete SystemOfRecordService.defaults.headers.common.Authorization;
  }
};

export enum SOREndpoints {
  // customers
  CUSTOMER_APPLICATIONS = '/customer/application/',
  CUSTOMER_PROFILE = '/customer/account/',
  CUSTOMER_PRODUCT = '/customer/product/',
  CUSTOMER_ACCOUNT_CREATE = '/account/create',
  CUSTOMER_SELECTED_PRODUCT = '/customer/product/selected/',
  MATCHES = '/matches',
  MATCHED_PRODUCTS = '/matched_products',

  // post products
  POST_SELECT_PRODUCT = '/select-product',
  POST_ACCEPT_QUOTE = '/accept-quote',
  POST_ACCEPT_OFFER = '/accept-offer',
  POST_VIEW_PRODUCT = '/view',

  // advisors
  ADVISOR_PROFILE = '/partner/account/',
  ADVISOR_ACCOUNT_CREATE = '/advisor/create',
  ADVISOR_REFERRALS = '/partner/referrals/',
  ADVISOR_REFERRALS_DASHBOARD = '/partner/referrals/dashboard', // <-- no trailing slash on one of these
  IDENTITY_CONFIRM = '/partner/identity/confirm/',
  IDENTITY_ASSOCIATE = '/partner/identity/associate/',

  // open banking
  OPEN_BANKING = '/customer/open-banking/',

  // directors
  DIRECTOR = '/customer/director/',
  ADDRESS = '/address', // /customer/director/${director_id}/address

  // Page leave Beacon
  PAGE_LEAVE = '/notification/submit/',

  // Auth
  SIGN_UP = '/auth/sign-up',
  LOGIN = '/auth/login',

  // Iterable
  ITERABLE_JWT = '/re-engagement/generate/',

  // Analytics
  TRACK_EVENT = '/analytics/',
}

export default SystemOfRecordService;
