import {css} from '@emotion/css';
import {Spacing} from '../variables/spacing';

import {mq, Breakpoints} from './media-queries';

export const containerStackMobile = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${mq(Breakpoints.TABLET)} {
    flex-direction: row;
  }
`;

export const columnStackableMobile = (mobileBottomMargin: Spacing = Spacing.L): string => css`
  &:not(:last-child) {
    margin-bottom: ${mobileBottomMargin};

    ${mq(Breakpoints.TABLET)} {
      margin-bottom: 0;
    }
  }
`;

export const columnQuarter = css`
  flex: 0 0 25%;
`;

export const columnThird = css`
  flex: 0 0 ${100 / 3}%;
`;

export const columnThreeQuarters = css`
  flex: 0 0 75%;
`;

export const columnHalf = css`
  flex: 0 0 50%;
`;

export const columnFull = css`
  flex: 0 0 100%;
`;

export const layout = {
  containerStackMobile,
  columnStackableMobile,
  columnQuarter,
  columnThird,
  columnThreeQuarters,
  columnHalf,
  columnFull,
};
