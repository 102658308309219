import {injectGlobal} from '@emotion/css';

export const cssVarsSpacing = (): void => injectGlobal`
  :root {
    --spacing-XXXL: 10rem;
    --spacing-XXL: 9rem;
    --spacing-XL: 8rem;
    --spacing-L: 6rem;
    --spacing-M: 4rem;
    --spacing-S: 3rem;
    --spacing-XS: 2rem;
    --spacing-XXS: 1rem;
    --spacing-XXXS: 0.5rem;
    --spacing-tide-S: 2.4rem;
    --spacing-tide-XS: 1.6rem;
    --spacing-tide-XXS: 0.8rem;
    --spaceless: 0;
    --spacing-minus-XXXL: -10rem;
    --spacing-minus-L: -6rem;
    --spacing-minus-M: -4rem;
    --spacing-minus-S: -3rem;
    --spacing-minus-XS: -2rem;
    --spacing-minus-XXS: -1rem;
    --spacing-minus-XXXS: -0.5rem;
    --header-min-height: 6.6rem; // @note only used once
    --ratio-16-to-9: 56.25%; // @note only used once
    --border-radius-S: 0.3rem;
    --border-radius-M: 1rem;
    --border-radius-L: 1.6rem;
  }
`;

export enum Spacing {
  XXXL = 'var(--spacing-XXXL)',
  XXL = 'var(--spacing-XXL)',
  XL = 'var(--spacing-XL)',
  L = 'var(--spacing-L)',
  M = 'var(--spacing-M)',
  S = 'var(--spacing-S)',
  XS = 'var(--spacing-XS)',
  XXS = 'var(--spacing-XXS)',
  XXXS = 'var(--spacing-XXXS)',
  TIDE_S = 'var(--spacing-tide-S)',
  TIDE_XS = 'var(--spacing-tide-XS)',
  TIDE_XXS = 'var(--spacing-tide-XXS)',
}

enum BorderRadius {
  S = 'var(--border-radius-S)',
  M = 'var(--border-radius-M)',
  L = 'var(--border-radius-L)',
}

export const ratio16to9 = 'var(--ratio-16-to-9)';
export const borderRadius = BorderRadius;

export const spacing = Spacing;
