import {Account, Scope, ObjectOfAny} from '@fo/shared-types';
import {JWT_ERROR} from '@fo/shared-types/constants';

// @todo create types for the response
const getAccountFromCognitoUserResponse = (response: ObjectOfAny): Account => {
  const {attributes, ChallengeName, ChallengeParameters, signInUserSession} = response;

  // when the user has a challenge, they don't have attributes
  // everything comes back as part of the challenge
  const userAttributes = attributes || JSON.parse(ChallengeParameters.userAttributes);
  const cognitoGroups = signInUserSession?.accessToken?.payload?.['cognito:groups'] || [];

  if (!signInUserSession?.accessToken?.jwtToken) {
    throw new Error(JWT_ERROR);
  }

  return {
    challengeName: ChallengeName,
    email: userAttributes.email,
    emailVerified: userAttributes.email_verified === true || userAttributes.email_verified === 'true',
    jwt: signInUserSession?.accessToken?.jwtToken,
    // advisors also have a customer scope, so for now we're ensuring users only have one scope
    scopes: cognitoGroups.includes('advisor') ? [Scope.ADVISOR] : [Scope.CUSTOMER],
    cognitoId: userAttributes.sub,
  };
};

export default getAccountFromCognitoUserResponse;
