import {injectGlobal} from '@emotion/css';

import {fonts, colours} from './variables';

export const addFonts = () => injectGlobal`
    @font-face {
      font-family: 'radikal';
      font-weight: 400;
      font-style: normal;
      font-display: swap;
      unicode-range: U+000-5FF;
      src: url('/fonts/radikal-regular.woff') format('woff');
    }
    @font-face {
      font-family: 'radikal';
      font-weight: 600;
      font-style: normal;
      font-display: swap;
      unicode-range: U+000-5FF;
      src: url('/fonts/radikal-medium.woff') format('woff');
    }
    @font-face {
      font-family: 'rubik';
      font-weight: 400;
      font-style: normal;
      font-display: swap;
      unicode-range: U+000-5FF;
      src: url('/fonts/rubik-regular.woff') format('woff');
    }
    @font-face {
      font-family: 'rubik';
      font-weight: 600;
      font-style: normal;
      font-display: swap;
      unicode-range: U+000-5FF;
      src: url('/fonts/rubik-medium.woff') format('woff');
    }
  `;

export const typography = () => injectGlobal`

.label {
  font-weight: ${fonts.weight.SEMI_BOLD};
  font-size: ${fonts.size.H4};
  &--emphasis {
    color: ${colours.semantic.INFORMATION};
  }
}

.noBreakText {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.link {
  color: ${colours.font.ASSISTIVE};
  text-decoration: none;
  &:focus {
    color: ${colours.brand.SECONDARY};
  }

  &:hover {
    color: ${colours.font.BLACK};
  }

  &--active,
  &.disabled,
  [disabled] {
    color: ${colours.brand.SECONDARY};
    pointer-events: none;
  }
}

a:not(.button):not(.unstyledLink) {
  color: ${colours.brand.SECONDARY};
  text-decoration: underline;

  &.hover,
  &:hover {
    color: ${colours.brand.SECONDARY};
    text-decoration: underline;
  }

  &.visited,
  &:visited,
  &.active,
  &:active {
    color: ${colours.semantic.INFORMATION_HOVER};
    text-decoration: underline;
  }
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

h1 {
  font-size: ${fonts.size.H1};
}
h2 {
  font-size: ${fonts.size.H2};
}
h3 {
  font-size: ${fonts.size.H3};
}
h4 {
  font-size: ${fonts.size.H4};
}
h5 {
  font-size: ${fonts.size.H5};
}
h6 {
  font-size: ${fonts.size.H6};
}
`;
