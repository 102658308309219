import {PRODUCTION} from '@fo/shared-types/environments';

export const Service = {
  CUSTOMER_APPLICATION_SERVICE: 'limosa',
  COMPANY_DATA_SERVICE: 'company-data-svc',
  OPEN_BANKING_SERVICE: 'open-banking-accounts-svc',
  SYSTEM_OF_RECORD_SERVICE: 'sor',
  DOCUMENT_UPLOAD_SERVICE: 'documents-svc',
  OPEN_ACCOUNTING_SERVICE: 'open-accounting-svc',
} as const;

type ValueOf<T> = T[keyof T];
type TService = ValueOf<typeof Service>;

const getServiceUrl = (service: TService): string => {
  if (process.env.STAGE === 'test') {
    return '';
  }
  if (process.env.STAGE === PRODUCTION) {
    return `https://api.tideplatform.uk/pcs/${service}`;
  }
  return `https://api.wip-tideplatform.uk/pcs/${service}`;
};

export default getServiceUrl;
