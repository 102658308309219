/**
 * coloursV2.ts
 * This file replaces the colours.ts file
 */
import {lighten as polishedLighten, darken as polishedDarken, desaturate} from 'polished';

const genericColours = {
  white: '#ffffff',
  grey: '#595959',
  lightGrey: '#eaeaea',
  black: '#0c0c0e',
  green: '#4EA256',
  tideOffWhite: '#F1F2F3',
};

const brandColours = {
  primary: '#fcc22d',
  secondary: '#1929d6',
  tertiary: '#dddddd',
  insights: '#7045cc',
  connect: '#7296df',
  tideBlue: '#0C169A',
};

export const colours = {
  generic: genericColours,
  brand: brandColours,
  font: {
    ...genericColours,
    link: brandColours.secondary,
    tideBlue: '#040966',
  },
  semantic: {error: '#d4351c', success: '#58c830'},
  background: {
    ...genericColours,
    midGray: '#C8C9D0',
    lightGray: '#f8f7f4',
    lightBlue: '#f1f7ff',
    lightGreen: '#f1fff3',
    lightYellow: '#fff7e2',
    faintYellow: '#FFF4D1',
    lightRed: '#fff1f1',
    lightBlueTide: '#EFF1FF',
    tideBlue: '#040966',
  },
};

const colourVariations = {
  lighter: 0.25,
  darker: 0.1,
  desaturated: 0.25,
};

export const lighten = (hex: string): string => polishedLighten(colourVariations.lighter, hex);
export const darken = (hex: string): string => polishedDarken(colourVariations.darker, hex);
export const disable = (hex: string): string =>
  polishedLighten(colourVariations.lighter, desaturate(colourVariations.desaturated, hex));
