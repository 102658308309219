import {Cookies} from 'react-cookie';
import {useEffect} from 'react';
import {v4 as uuidV4} from 'uuid';

import {cookieDomain, hasSecureCookie, FOID_COOKIE_NAME} from '@fo/shared-types/constants';

const useHandleFoid = (): void => {
  // window.FOID sets FundingOptionsID (FOID) onto global namespace to send to Google Tag Manager Custom Dimension

  useEffect(() => {
    const cookies = new Cookies();
    const foid = cookies.get(FOID_COOKIE_NAME);

    if (foid) {
      window.FOID = foid;
    } else {
      const newFoid = uuidV4();
      cookies.set(FOID_COOKIE_NAME, newFoid, {
        maxAge: 180 * 24 * 60 * 60,
        path: '/',
        domain: cookieDomain,
        secure: hasSecureCookie,
      });
      window.FOID = newFoid;
    }
  }, []);
};

export default useHandleFoid;
