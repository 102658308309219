// ////////////////////////////////////////////////
// /// APPLICATION & QUOTE FORM & RELATED TYPES ///
// ////////////////////////////////////////////////

// Quote Form

export enum Title {
  MR = 'Mr',
  MRS = 'Mrs',
  MISS = 'Miss',
  MS = 'Ms',
  DR = 'Dr.',
  MX = 'Mx',
  OPT_OUT = 'Prefer not to say',
}

export enum PropertyFinanceType {
  DEVELOPMENT = 'development_finance',
  COMMERCIAL = 'commercial_mortgage',
  BRIDGING = 'bridging_finance',
  BUY_TO_LET = 'buy_to_let',
}

export enum PropertyDevelopmentType {
  DEVELOPMENT = 'development_finance',
  COMMERCIAL = 'commercial_mortgage',
  BUY_TO_LET = 'buy_to_let',
  BRIDGING = 'bridging_finance',
}

export enum AssetCategoryType {
  CONSTRUCTION = 'construction',
  ENERGY = 'energy',
  EXCAVATION = 'excavation',
  FORKLIFTS = 'forklifts',
  AGRICULTURAL_MACHINERY = 'agricultural_machinery',
  MARITIME = 'maritime',
  RECYCLING = 'recycling',
  TRANSPORT = 'transport',
  CLEANING_MACHINES = 'cleaning_machines',
  OTHER = 'other',
}

export enum AssetFinanceType {
  REFINANCING = 'refinancing',
  PURCHASING = 'purchasing',
}

// could use RadioYesNoField but this is more descriptive
export enum ProfitableInLast12MonthsValue {
  YES = 'yes',
  NO = 'no',
  I_DONT_KNOW = 'i_do_not_know',
}

export enum LegalStatus {
  LIMITED_COMPANY = 'limited_company',
  LIMITED_PARTNERSHIP = 'limited_partnership',
  ORDINARY_PARTNERSHIP = 'ordinary_partnership',
  PUBLIC_LIMITED = 'public_limited',
  SOLE_TRADER = 'sole_trader',
  PROPRIETORSHIP = 'proprietorship',
  NAMELESS_SOCIETY = 'nameless_society',
  GENERAL_PARTNERSHIP_VOF = 'general_partnership_vof',
  PRIVATE_COMPANY = 'private_company',
  OTHER = 'other',
}

export enum FinancePurpose {
  ACQUISITION = 'acquisition',
  ASSET_FINANCE = 'asset_finance',
  BILL_OR_TAX_PAYMENT = 'bill_or_tax_payment',
  BRIDGING = 'bridging',
  BUSINESS_STARTUP = 'business_startup',
  BUYOUT = 'buyout',
  BUY_IN_OR_OUT = 'buyin_or_buyout',
  DEBT_REFINANCE = 'debt_refinance',
  GROWTH_CAPITAL = 'growth_capital',
  OTHER = 'Other',
  PROPERTY_PURCHASE = 'property_purchase',
  PROPERTY_FINANCE = 'Property Finance',
  WORKING_CAPITAL = 'working_capital',
  INVOICE_FINANCE = 'invoice_finance',
}

export enum TermLength {
  MONTHS = 1,
  YEARS = 12,
  MANY_YEARS = 60,
  UNKNOWN = 0,
}

export enum Industry {
  AGRICULTURE_FORESTRY_FISHING = 'AFF',
  ARCHITECTURE_ENGINEERING = 'AE',
  BEAUTY_CARE = 'BV',
  BUSINESS_SUPPORT_SERVICES = 'BSS',
  CAR_DEALERS_GARAGES_MECHANICS = 'CDM',
  CLEANING_LANDSCAPING_GARDENING = 'CLG',
  CONSTRUCTION = 'CON',
  E_COMMERCE = 'EC',
  EDUCATION_TRAINING = 'ET',
  FINANCE_INSURANCE = 'FI',
  HEALTH_CARE = 'HC',
  HOTELS_RESTAURANTS_PUBS = 'HRP',
  IT_COMMUNICATIONS = 'ITC',
  LEISURE_OTHER_SERVICES = 'LO',
  MANUFACTURING_FOOD_PROCESSING_PRINTING = 'MFP',
  OTHER = 'OTH',
  PROFESSIONAL_SERVICES = 'PS',
  PROPERTY_REAL_ESTATE = 'PRE',
  PUBLISHING_FILM_MEDIA_PRODUCTION = 'PFM',
  REAL_ESTATE = 'PRE',
  RETAIL = 'RET',
  SPORTS_AND_RECREATION = 'SR',
  TRANSPORT_LOGISTICS = 'TL',
  WHOLESALE = 'WHL',
}

export enum Role {
  DIRECTOR = 'D',
  SHAREHOLDER = 'S',
  OWNER = 'OW',
  COLLABORATOR = 'C',
  EXTERNAL_CONSULTANT = 'EC',
  ACCOUNTANT = 'AC',
  OTHER = 'O',
}

export enum ResidentialStatus {
  TENANT = 'tenant',
  OWNER_WITH_MORTGAGE = 'owner_with_mortgage',
  OWNER_NO_MORTGAGE = 'owner_no_mortgage',
  RENT_FREE = 'rent_free',
}

export enum WhenFinanceRequired {
  ASAP = 'asap',
  NEXT_WEEK = 'next_week',
  SHOPPING_AROUND = 'shopping_around',
}

// Multi

export enum SalesQueue {
  SALES_QUEUE_LOW = 1,
  SALES_QUEUE_MEDIUM_LOW = 2,
  SALES_QUEUE_MEDIUM_HIGH = 3,
  SALES_QUEUE_HIGH = 4,
  SALES_QUEUE_BRS = 5,
  SALES_QUEUE_PROPERTY_LOW = 6,
  SALES_QUEUE_PROPERTY_HIGH = 7,
  SALES_QUEUE_ASSET_HIGH = 8,
  SALES_QUEUE_ASSET_LOW = 9,
  SALES_QUEUE_SOLE_TRADER_HIGH = 10,
  SALES_QUEUE_SOLE_TRADER_MID = 11,
  SALES_QUEUE_BROKER_ONLY_HIGH = 12,
  SALES_QUEUE_BROKER_LOW = 13,
  SALES_QUEUE_NL_LOWSTARTER = 14,
  SALES_QUEUE_NL_SDR = 15,
  SALES_QUEUE_NL_LOW_BFS = 16,
  SALES_QUEUE_NL_BFS = 17,
  SALES_QUEUE_REVENUE_HIGH = 18,
  SALES_QUEUE_STARTUP_LOANS = 21,
  SALES_QUEUE_CCJ_NON_HOMEOWNER = 22,
  SALES_QUEUE_STARTUP_CCJ_NON_HOMEOWNER = 23,
  SALES_QUEUE_BRIDGING_LOAN = 24,
  SALES_QUEUE_NL_SMALL = 25,
  SALES_QUEUE_NL_HIGHSTARTER = 26,
}

export enum ProductCost {
  LO = 'LO',
  ME = 'ME',
  HI = 'HI',
}

export enum ProductSpeed {
  MI = 'MI',
  HO = 'HO',
  DA = 'DA',
  WE = 'WE',
  FO = 'FO',
  MO = 'MO',
}

export enum ProductType {
  BROKER = 'broker',
  DIGITAL = 'digital',
}

export enum QuoteType {
  SHORT_TERM_LOAN = 'short_term_loan',
  TERM_LOAN = 'term_loan',
  REVOLVING_CREDIT_FACILITY = 'revolving_credit_facility',
  BUSINESS_CREDIT_CARD = 'business_credit_card',
  MERCHANT_CASH_ADVANCE = 'merchant_cash_advance',
  ASSET_FINANCE_HIRE_PURCHASE = 'asset_finance_hire_purchase',
  PROPERTY = 'property',
  INVOICE_FINANCE = 'invoice_finance',
}

export enum ApplicationStatus {
  ACCEPTED = 'accepted',
  CLOSED_LOST = 'closed_lost',
  CREDIT_APPROVED = 'credit_approved',
  CUSTOMER_SIGNED = 'customer_signed',
  DRAWNDOWN = 'drawndown',
  FAILED_SUBMISSION = 'failed_submission',
  LENDER_REVIEWING = 'lender_reviewing',
  PENDING_DOCUMENTATION = 'pending_documentation',
  REJECTED = 'rejected',
  WAITING_FOR_CUSTOMER = 'waiting_for_customer',
  WAITING_FOR_FUNDINGOPTIONS = 'waiting_for_fundingoptions',
  WAITING_FOR_LENDER = 'waiting_for_lender',
}

export enum AssetType {
  LIGHT_COMMERCIAL_VEHICLES = 'light_commercial_vehicles',
  CARS = 'cars',
  HEAVY_COMMERCIAL_VEHICLES = 'heavy_commercial_vehicles',
  CONSTRUCTION = 'construction',
  MISCELLANEOUS = 'miscellaneous',
  PRODUCTION = 'production',
  TRAILERS = 'trailers',
  MATERIAL_HANDLING_EQUIPMENT = 'material_handling_equipment',
  BUSES_AND_COACHES = 'buses_and_coaches',
  AGRICULTURE = 'agriculture',
  HIGH_TECH = 'hi_tech',
  HEALTHCARE = 'healthcare',
  IT = 'it',
  MOTORCYCLES = 'motorcycles',
  FURNITURE = 'furniture',
  MUNICIPAL = 'municipal',
  CATERING = 'catering',
  CONTAINERS = 'containers',
  RAIL = 'rail',
  CARAVANS = 'caravans',
}

export enum DebitType {
  MINIMUM = 'minimum',
  FULL_BALANCE = 'fullbalance',
}

export enum TypeOfWorkingCapital {
  OVERDRAFT = 'overdraft',
  LOANS = 'loans',
  ASSET_FINANCE = 'asset_finance',
  INVOICE_FINANCE = 'invoice_finance',
  CREDIT_CARD = 'credit_card',
}

export enum TypeOfDebtRefinance {
  OVERDRAFT = 'overdraft',
  LOANS = 'loans',
  ASSET_FINANCE = 'asset_finance',
  INVOICE_FINANCE = 'invoice_finance',
}
