/* eslint-disable react/jsx-props-no-spreading */
import React, {ReactElement} from 'react';
import {Helmet} from 'react-helmet';

import SiteContextProvider, {SiteContextProviderProps} from '@fo/shared-providers/SiteContextProvider';
import {injectGlobalStyles} from '@fo/shared/styles/global.main';
import Identity from '@fo/shared-data/apis/Identity';
import {identifyUser} from '@fo/shared-analytics/tracking/segment';
import CookieLoader from './components/CookieLoader';

export interface WrapPageElementProps {
  element: ReactElement;
  props?: Partial<SiteContextProviderProps>;
}

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const WrapPageElement = ({element}: WrapPageElementProps): ReactElement => {
  injectGlobalStyles();

  return (
    <SiteContextProvider
      dispatchTrackingSuperEvent={(user) => {
        // Identify user in Segment
        if (user.cognitoId && user.email) {
          identifyUser({userId: user.cognitoId, email: user.email, name: `${user?.first_name} ${user?.last_name}`});
        }
      }}
      identity={Identity}
    >
      <Helmet>
        {/* https://my.onetrust.com/articles/en_US/knowledge/UUID-518074a1-a6da-81c3-be52-bae7685d9c94 */}
        <script
          type="text/plain"
          className="optanon-category-C0004"
          id="6senseWebTag"
          src="https://j.6sc.co/j/528a739e-676d-4b3c-b89c-8b4f73d8f083.js"
        />
        <link rel="preload" as="font" href="/fonts/radikal-regular.woff" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/radikal-medium.woff" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/rubik-regular.woff" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/rubik-medium.woff" crossOrigin="anonymous" />
        <meta name="google-site-verification" content="qDSfLz8CeacyY7Gonqdt9wb5E6_FLRix9Yv0mQ7Emts" />
      </Helmet>
      <CookieLoader />
      {element}
    </SiteContextProvider>
  );
};

export default WrapPageElement;
