export enum Breakpoints {
  MOBILE = 320,
  TABLET = 760,
  DESKTOP = 1024,
  DESKTOP_LG = 1152,
  DESKTOP_RETINA = 1440,
}

export const mq = (breakpoints: Breakpoints, upTo?: Breakpoints): string =>
  `@media (min-width: ${breakpoints}px${upTo ? `) and (max-width: ${upTo - 1}px` : ''})`;
