import {User} from '@fo/shared-types';

const getBearerToken = (user?: User): string => {
  if (!user) {
    return '';
  }
  return `Bearer ${(user && user.jwt) || ''}`;
};

export default getBearerToken;
