export const enum HttpStatusCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NEEDS_REVIEW = 403,
  NOT_FOUND = 404,
  CONFLICTING_REQUEST = 409,
  INTERNAL_SERVER_ERROR = 500,
}

export const NETWORK_ERROR = 'NETWORK_ERROR';

export type RequestError = typeof NETWORK_ERROR | HttpStatusCode | string;
