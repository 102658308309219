import {Cookies} from 'react-cookie';
import {useEffect, useState} from 'react';
import {cookieDomain, hasSecureCookie, LANDING_URL_COOKIE_NAME} from '@fo/shared-types/constants';

const useHandleLandingUrl = (): string | undefined => {
  const [landingUrl, setLandingUrl] = useState<string | undefined>();
  useEffect(() => {
    const cookies = new Cookies();

    if (cookies.get(LANDING_URL_COOKIE_NAME)) {
      setLandingUrl(cookies.get(LANDING_URL_COOKIE_NAME));
      return;
    }

    setLandingUrl(window.location.href);
    cookies.set(LANDING_URL_COOKIE_NAME, window.location.href, {
      maxAge: 24 * 60 * 60,
      path: '/',
      domain: cookieDomain,
      secure: hasSecureCookie,
    });
  }, []);

  return landingUrl;
};

export default useHandleLandingUrl;
