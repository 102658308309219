/**
 * This file should not be used on the new Design System
 * it will be eventually removed
 */

import {injectGlobal} from '@emotion/css';

export const cssVarsColours = (): void => injectGlobal`
  :root {
    /* brand-colors */
    --brand-color-primary: #fcc22d;
    --brand-color-primary-disabled: #ffd466;
    --brand-color-secondary: #1929d6;
    --brand-color-secondary-disabled: #7296df;
    --brand-color-tertiary: #dddddd;
    --brand-color-tertiary-disabled: #eaeaea;
    --brand-color-insights: #7045cc;
    /* semantic-colors */
    --semantic-color-error: #d4351c;
    --semantic-color-warning: #fcc22d;
    --semantic-color-information: #1929d6;
    --semantic-color-success: #58c830;
    --semantic-color-success-disabled: #b8e1a9;
    --semantic-color-disabled: rgba(0, 0, 0, 0.4);
    --semantic-color-placeholder-active: #909090;
    /* semantic-color-hover-states */
    --semantic-color-warning-hover: #e2ab1c;
    --semantic-color-information-hover: #294e99;
    --semantic-color-default-hover: #b0b0b0;
    /* font-colors */
    --font-color-black: #0c0c0e;
    --font-color-dark-gray: #595959;
    --font-color-assistive: #595959;
    --placeholder-gray: #b5b5b5;
    --font-color-light-gray: #939393;
    --font-color-information: var(--semantic-color-information);
    --font-color-error: var(--semantic-color-error);
    --font-color-warning: #ca9306;
    --font-color-disabled: var(--semantic-color-disabled);
    /* general-colours */
    --background-color-off-white: #f8f8f8;
    --background-color-section: #f8f7f4;
    --background-color-section-hover: #efeeea;
    --background-color-main: #ffffff;
    --background-color-gray: #e9e9e9;
    --background-color-blue: var(--brand-color-secondary-disabled);
    --background-color-light-blue: #f1f7ff;
    --general-color-white: #ffffff;
    /* input-colours */
    --selected-color-light-gray: #f1f1f1;
    --field-disabled-color: #ededed;
    /* separator-colours */
    --border-separator-color-off-white: #e1e1e1;
    --border-color-light-gray: #dddddd;
    --border-color-gray: #eaeaea;
    /* scoring-colors */
    --score-excellent-color: #4ea256;
    --score-excellent-background-color: #f1fff3;
    --score-good-color: #bd8e15;
    --score-good-background-color: #fff7e2;
    --score-average-color: #a24e4e;
    --score-average-background-color: #fff1f1;
    --stat-label-color: #4e72a2;
  }
`;

export enum BrandColours {
  PRIMARY = 'var(--brand-color-primary)',
  PRIMARY_DISABLED = 'var(--brand-color-primary-disabled)',
  SECONDARY = 'var(--brand-color-secondary)',
  SECONDARY_DISABLED = 'var(--brand-color-secondary-disabled)',
  TERTIARY = 'var(--brand-color-tertiary)',
  TERTIARY_DISABLED = 'var(--brand-color-tertiary-disabled)',
  INSIGHTS = 'var(--brand-color-insights)',
}

export enum SemanticColours {
  ERROR = 'var(--semantic-color-error)',
  WARNING = 'var(--semantic-color-warning)',
  WARNING_HOVER = 'var(--semantic-color-warning-hover)',
  INFORMATION = 'var(--semantic-color-information)',
  INFORMATION_HOVER = 'var(--semantic-color-information-hover)',
  SUCCESS = 'var(--semantic-color-success)',
  SUCCESS_DISABLED = 'var(--semantic-color-success-disabled)',
  DISABLED = 'var(--semantic-color-disabled)',
  PLACEHOLDER_ACTIVE = 'var(--semantic-color-placeholder-active)',
  DEFAULT_HOVER = 'var(--semantic-color-default-hover)',
}

export enum FontColours {
  BLACK = 'var(--font-color-black)',
  DARK_GRAY = 'var(--font-color-dark-gray)',
  ASSISTIVE = 'var(--font-color-assistive)',
  PLACEHOLDER_GRAY = 'var(--placeholder-gray)',
  LIGHT_GRAY = 'var(--font-color-light-gray)',
  INFORMATION = 'var(--font-color-information)',
  ERROR = 'var(--font-color-error)',
  WARNING = 'var(--font-color-warning)',
  DISABLED = 'var(--font-color-disabled)',
}

export enum BackgroundColours {
  OFF_WHITE = 'var(--background-color-off-white)',
  SECTION = 'var(--background-color-section)',
  SECTION_HOVER = 'var(--background-color-section-hover)',
  MAIN = 'var(--background-color-main)',
  GRAY = 'var(--background-color-gray)',
  BLUE = 'var(--background-color-blue)',
  LIGHT_BLUE = 'var(--background-color-light-blue)',
}

export enum GeneralColours {
  WHITE = 'var(--general-color-white)',
}

export enum SelectedColours {
  LIGHT_GRAY = 'var(--selected-color-light-gray)',
}

export enum FieldColours {
  DISABLED = 'var(--field-disabled-color)',
}

export enum BorderColours {
  SEPARATOR_OFF_WHITE = 'var(--border-separator-color-off-white)',
  LIGHT_GRAY = 'var(--border-color-light-gray)',
  GRAY = 'var(--border-color-gray)',
}

export enum ScoreColours {
  EXCELLENT = 'var(--score-excellent-color)',
  EXCELLENT_BG = 'var(--score-excellent-background-color)',
  GOOD = 'var(--score-good-color)',
  GOOD_BG = 'var(--score-good-background-color)',
  AVERAGE = 'var(--score-average-color)',
  AVERAGE_BG = 'var(--score-average-background-color)',
  STAT_LABEL = 'var(--stat-label-color)',
}

export const colours = {
  brand: BrandColours,
  semantic: SemanticColours,
  font: FontColours,
  background: BackgroundColours,
  general: GeneralColours,
  selected: SelectedColours,
  field: FieldColours,
  border: BorderColours,
  score: ScoreColours,
};
