import {css} from '@emotion/css';

import {spacing} from '../variables';
import {negative} from './negative';

export const hoverAndPressInteraction = css`
  transition: all ease-in-out 0.1s;
  &:hover {
    transform: translateY(${negative(spacing.XXXS)});
  }
  &:active {
    transform: translateY(0);
  }
`;
